"use client"
import { usePathname } from "next/navigation"
import { useRouter } from "next/router"
import type { FC } from "react"

import styles from "./LatestNewsTeaserRowFooter.module.scss"

import { Animated } from "@/components/common/animated"
import { Button } from "@/components/common/button"
import { Grid, GridRow } from "@/components/common/grid"

import { create } from "@/helpers/bem"
import { trackPageChange } from "@/helpers/tracking"

const bem = create(styles, "LatestNewsTeaserRowFooter")

export const LatestNewsTeaserRowFooter: FC = () => {
  const path = usePathname()
  const { query } = useRouter()
  const currentLocale = query.lang
  return (
    <Grid>
      <GridRow margin="0 auto" md={4} xs={12}>
        <Animated className={bem("button")}>
          <Button
            fullWidth
            title={""} // t("pages.components.latestNewsTeaserRow.button")
            variant="outlined"
            redirect={{
              href: `/${currentLocale}/blog/`,
            }}
            onClick={() =>
              trackPageChange(
                "latest_news_teaser",
                path,
                `/${currentLocale}/blog/`,
                "blog",
              )
            }
          />
        </Animated>
      </GridRow>
    </Grid>
  )
}
