"use client"
import { useTheme } from "@mui/material/styles"
import type { FC } from "react"

import styles from "./Image.module.scss"

import { NextImage, type NextImageProps } from "@/components/common/image"

import { create } from "@/helpers/bem"
import { resolveDarkModeAsset } from "@/helpers/theme"
import type { UploadFileEntityResponse } from "@/types/generated/strapi/graphql"

const bem = create(styles, "Image")

export type ImageProps = {
  className?: string
  light: UploadFileEntityResponse
  dark?: UploadFileEntityResponse
} & Omit<NextImageProps, "src" | "alt">

export const Image: FC<ImageProps> = ({ light, dark, className, ...props }) => {
  const theme = useTheme()
  const isDark = theme.palette.mode === "dark"
  const lightAttr = light?.data?.attributes
  const attr = isDark ? (dark?.data?.attributes ?? lightAttr) : lightAttr
  return (
    <div className={bem(undefined, undefined, className)}>
      <NextImage
        fill
        style={{ objectFit: "cover" }}
        {...props}
        alt={attr?.alternativeText ?? ""}
        caption={attr?.caption ?? undefined}
        src={resolveDarkModeAsset(theme, light, dark) ?? ""}
      />
    </div>
  )
}
