"use client"
import Skeleton from "@mui/material/Skeleton"
import { useTheme } from "@mui/material/styles"
import dynamic from "next/dynamic"
import { usePathname } from "next/navigation"
import { useRouter } from "next/router"
import type { FC } from "react"

import styles from "./LatestNewsTeaserRowItem.module.scss"

import { Grid, GridRow } from "@/components/common/grid"

import { useLatestPosts } from "@/helpers/api/client/post"
import { create } from "@/helpers/bem"
import { resolveDarkModeAsset } from "@/helpers/theme"
import { trackPageChange } from "@/helpers/tracking"
import type {
  UploadFileEntityResponse,
  LatestNewsTeaserRowFragmentFragment,
} from "@/types/generated/strapi/graphql"
import type { Locale } from "@/utils/translations/i18n-config"

const bem = create(styles, "LatestNewsTeaserRowItem")

const LazyPostTeaser = dynamic(
  () =>
    import("@/components/common/post-teaser").then(({ PostTeaser }) => ({
      default: PostTeaser,
    })),
  {
    loading: () => (
      <Skeleton
        className={bem("skeleton")}
        height={400}
        variant="rectangular"
      />
    ),
  },
)

export type LatestNewsTeaserRowItemProps = {
  spacing: LatestNewsTeaserRowFragmentFragment["spacing"]
}

export const LatestNewsTeaserRowItem: FC<LatestNewsTeaserRowItemProps> = ({
  spacing,
}) => {
  const path = usePathname()
  const theme = useTheme()
  const { query } = useRouter()
  const locale = query.lang as Locale
  const { data } = useLatestPosts(locale)
  return (
    <Grid>
      {data?.articles?.data?.map(({ id, attributes }) => {
        const href =
          `blog/${attributes?.category.data?.attributes?.slug}/${attributes?.slug}` ??
          "#"
        return (
          <GridRow key={id} md={4} spacing={spacing ?? 2} xs={12}>
            <LazyPostTeaser
              category={attributes?.category.data?.attributes?.name}
              className={bem("teaser")}
              content={attributes?.description ?? ""}
              image={
                attributes?.cover
                  ? {
                      src:
                        resolveDarkModeAsset(
                          theme,
                          attributes?.cover as UploadFileEntityResponse,
                        ) ?? "",
                      alt:
                        attributes?.cover?.data?.attributes?.alternativeText ??
                        "",
                    }
                  : undefined
              }
              redirect={{
                href,
                // TODO: TRANSLATION FIX NEEDED
                // label: t("pages.components.latestNewsTeaserRow.link"),
              }}
              title={{
                content: attributes?.title ?? "",
              }}
              onClick={() =>
                trackPageChange("latest_news_teaser", path, href, "article")
              }
            />
          </GridRow>
        )
      })}
    </Grid>
  )
}
