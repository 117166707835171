import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.3_react@18.3.1__@emotion+styled_3gdkuytgf63lqyxknz732z3vkq/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.3_react@18.3.1__@emotion+styled_3gdkuytgf63lqyxknz732z3vkq/node_modules/@mui/material/Unstable_Grid2/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@14.2.11_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.78.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@14.2.11_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.78.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@14.2.11_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.78.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@14.2.11_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.78.0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/[lang]/components/ContentContainer/ContentContainer.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/[lang]/components/FAQs/FAQ.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/[lang]/components/FeatureCampaign/FeatureCampaignContent.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/[lang]/components/Headline/Headline.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/[lang]/components/ContentArea/ContentArea.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/[lang]/components/FAQs/FAQs.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/[lang]/components/FeatureCampaign/FeatureCampaign.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/[lang]/components/HeroBanner/HeroBanner.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["HeroBannerActions"] */ "/vercel/path0/frontend/src/app/[lang]/components/HeroBanner/HeroBannerActions.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/[lang]/components/Image/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LatestNewsTeaserRowFooter"] */ "/vercel/path0/frontend/src/app/[lang]/components/LatestNewsTeaserRow/LatestNewsTeaserRowFooter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LatestNewsTeaserRowItem"] */ "/vercel/path0/frontend/src/app/[lang]/components/LatestNewsTeaserRow/LatestNewsTeaserRowItem.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/[lang]/components/RichText/RichText.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/[lang]/components/LatestNewsTeaserRow/LatestNewsTeaserRow.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/[lang]/components/Suspense/Suspense.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/[lang]/components/TeaserRow/TeaserRow.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/common/animated/Animated.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/common/animatedText/AnimatedText.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/common/card/Card.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/common/headline/Headline.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/common/image-text/ImageText.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/common/image/Image.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/common/link/Link.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/common/lottie/Lottie.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/common/rich-text/RichText.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/common/teaser/Teaser.module.scss");
