"use client"
import { useState, type FC } from "react"

import styles from "./FAQ.module.scss"

import { Accordion, type AccordionItem } from "@/components/common/accordion"

import { create } from "@/helpers/bem"

const bem = create(styles, "FAQ")

export type FAQProps = {
  id: string
  faqs: AccordionItem[]
}

export const FAQ: FC<FAQProps> = ({ id, faqs }) => {
  const [expanded, setExpanded] = useState<number | null>(null)
  return (
    <Accordion
      className={bem()}
      expanded={expanded}
      id={id}
      items={faqs}
      onChange={(index) => setExpanded(index)}
    />
  )
}
