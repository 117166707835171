"use client"
import { useGraphQL } from "./lib/useGraphQL"

import {
  GET_POSTS_BY_CATEGORY_SLUG,
  GET_LATEST_POSTS,
} from "@/queries/blog/posts"

import type {
  PostsByCategorySlugQuery,
  LatestPostsQuery,
} from "@/types/generated/strapi/graphql"
import { REVALIDATION_TAGS } from "@/utils/enviroment"

import type { Locale } from "@/utils/translations/i18n-config"

// Custom hook to fetch posts by category slug
export const usePostsByCategorySlug = (
  locale: Locale,
  slug: string,
  page: number = 1,
  pageSize: number = 10,
) =>
  useGraphQL<PostsByCategorySlugQuery>(
    GET_POSTS_BY_CATEGORY_SLUG,
    {
      locale,
      slug,
      pagination: {
        page,
        pageSize,
      },
    },
    {
      fetchOptions: {
        next: { tags: REVALIDATION_TAGS.articles },
      },
    },
  )

// Custom hook to fetch latest posts
export const useLatestPosts = (locale: Locale) =>
  useGraphQL<LatestPostsQuery>(
    GET_LATEST_POSTS,
    {
      locale,
    },
    {
      fetchOptions: {
        next: { tags: REVALIDATION_TAGS.articles },
      },
    },
  )
