"use client"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { type FC, useMemo, useState } from "react"

import { Animated } from "../animated"
import { Button } from "../button"
import { Grid, GridRow } from "../grid"
import { Headline } from "../headline"
import { NextImage } from "../image"
import { Lottie } from "../lottie"
import { RichText } from "../rich-text"

import { ImageTextProps } from "./ImageText.model"
import styles from "./ImageText.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "ImageText")

export const ImageText: FC<ImageTextProps> = ({
  animatedBorder,
  reverse,
  subTitle,
  title,
  content,
  animation,
  image,
  button,
}) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const themeModifier = {
    [mode]: true,
  }
  const [lineVisible, setLineVisible] = useState(false)
  const screenLargerThanMd = useMediaQuery(theme.breakpoints.up("md"))
  const screenSmallerThanLg = useMediaQuery(theme.breakpoints.down("lg"))
  const screenSmallerThanSm = useMediaQuery(theme.breakpoints.down("sm"))
  const imageSize = useMemo(() => {
    let imageSize = 600
    if (screenSmallerThanSm) {
      imageSize = theme.breakpoints?.values?.xs
    } else if (screenSmallerThanLg) {
      imageSize = 500
    }
    return {
      width: imageSize,
      height: imageSize,
    }
  }, [theme.breakpoints, screenSmallerThanSm, screenSmallerThanLg])
  const leftColumnMd = image
    ? animatedBorder
      ? 4
      : 6
    : animatedBorder
      ? 10
      : 12
  return (
    <div className={bem()}>
      <Grid
        className={bem("grid", {
          "is-reverse": !!reverse,
        })}
      >
        {animatedBorder && (
          <GridRow className={bem("animated__border__wrapper")} sm={2} xs={3}>
            <Animated
              className={bem("animated__border", {
                "is-visible": lineVisible,
                [`${animatedBorder?.direction}--${mode}` ??
                `top-to-bottom--${mode}`]: !!animatedBorder?.direction,
                [mode]: true,
              })}
              onAnimate={(visible) => setLineVisible(visible)}
            />
          </GridRow>
        )}
        <GridRow className={bem("content")} md={leftColumnMd} xs={10}>
          <Animated
            animation={screenLargerThanMd ? "left-right" : "bottom-top"}
          >
            {subTitle && (
              <Headline
                highlight
                className={bem("sub__headline", undefined, subTitle?.className)}
                size="sm"
                type="h3"
                variant="primary"
                {...subTitle}
              >
                {subTitle?.content}
              </Headline>
            )}
            <Headline
              className={bem("headline", undefined, title?.className)}
              size="lg"
              type="h2"
              {...title}
            >
              {title.content}
            </Headline>
            {content && (
              <RichText className={bem("content__paragraph", themeModifier)}>
                {content}
              </RichText>
            )}
            {button && (
              <Button
                size="large"
                variant="outlined"
                {...button}
                className={bem("button", undefined, button?.className)}
              />
            )}
          </Animated>
        </GridRow>
        {image && (
          <GridRow className={bem("image")} md={6} xs={10}>
            <Animated
              animation={screenLargerThanMd ? "right-left" : "bottom-top"}
              className={bem("animated__container")}
            >
              {animation ? (
                <Lottie
                  animationName={animation}
                  className={bem("animation")}
                />
              ) : (
                <NextImage {...imageSize} {...image} />
              )}
            </Animated>
          </GridRow>
        )}
      </Grid>
    </div>
  )
}
