"use client"
import { useState, useCallback, type FC, type KeyboardEvent } from "react"

import styles from "./HeroBannerActions.module.scss"

import { Card } from "@/components/common/card"
import { Headline } from "@/components/common/headline"
import { Icon } from "@/components/common/icon"
import { create } from "@/helpers/bem"
import { useLeadForm, type LeadFormState } from "@/store/leadForm"
import { LEAD_FORM_SOLUTIONS, type LeadFormSolutions } from "@/utils/leadForm"
import type { CommonTranslations } from "@/utils/translations"

const bem = create(styles, "HeroBannerActions")

export type HeroBannerActionsProps = {
  translations?: CommonTranslations["services"]["leadForm"]["areas"]
}

export const HeroBannerActions: FC<HeroBannerActionsProps> = ({
  translations,
}) => {
  const { activeArea, updateLeadForm } = useLeadForm()
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(1)

  const handleActionClick = useCallback(
    (action: LeadFormSolutions) => {
      let updatedState: Partial<LeadFormState> = { open: true }
      if (activeArea !== action.key) {
        updatedState = {
          ...updatedState,
          activeArea: action.key,
          activeStep: "solution",
        }
      }
      updateLeadForm(updatedState)
    },
    [activeArea, updateLeadForm],
  )

  const handleMouseEnter = useCallback((index: number) => {
    setHoveredIndex(index)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setHoveredIndex(1)
  }, [])

  return (
    <div className={bem()}>
      <div className={bem("actions")}>
        {LEAD_FORM_SOLUTIONS.map((action, i: number) => {
          const isActive = hoveredIndex === i
          return (
            <Card
              key={`hero-banner-form-action-${action.key}`}
              animated
              className={bem("card", { "is-active": isActive })}
              contentClassName={bem("card__content")}
              highlight={isActive}
              onClick={() => handleActionClick(action)}
              onMouseEnter={() => handleMouseEnter(i)}
              onMouseLeave={handleMouseLeave}
              onKeyDown={(e: KeyboardEvent) =>
                e.code === "Enter" && updateLeadForm({ open: true })
              }
            >
              <Icon className={bem("icon")} name={action.icon} size="lg" />
              <Headline
                highlight
                align="center"
                className={bem("title", { "is-active": isActive })}
                size="lg"
                type="h2"
                variant={isActive ? "secondary" : "inherit"}
              >
                {translations?.[action.key].label}
              </Headline>
              <p className={bem("content")}>
                {translations?.[action.key].description}
              </p>
            </Card>
          )
        })}
      </div>
    </div>
  )
}
