import { gql } from "graphql-request"

export const QuoteFragment = gql`
  fragment QuoteFragment on ComponentSectionsQuote {
    title
    titleOptions {
      type
      size
      variant
    }
    subTitle
    subTitleOptions {
      type
      size
      variant
    }
    variant
    quote
    authorImage {
      data {
        attributes {
          url
          alternativeText
        }
      }
    }
    authorName
    authorPosition
  }
`
